/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationname">{{cvNameLabel}}<span class="primary-color font-size-16">*</span></label>
                  <select v-model="vmGPathrRoadmapExtraFormData.name" class="form-control" title="Select Roadmap Name">
                    <option v-for="(names, index) of name" :key="(index+1)" :value="names.value">
                      {{names.value}}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationtitle">{{cvTitleLabel}}<span class="primary-color font-size-16">*</span></label>
                  <input
                    v-model="vmGPathrRoadmapExtraFormData.title"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdescription">{{cvDescriptionLabel}}<span class="primary-color font-size-16">*</span></label>
                  <textarea
                    v-model="vmGPathrRoadmapExtraFormData.description"
                    type="text"
                    class="form-control textarea"
                    required ></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationurl">{{cvUrlLabel}}<span class="primary-color font-size-16">*</span></label>
                  <input
                    v-model="vmGPathrRoadmapExtraFormData.url"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <!-- <div class="col-md-12 mb-3">
                  <label for="validationimage">{{cvImageLabel}}<span class="primary-color font-size-16">*</span></label>
                  <input
                    v-model="vmGPathrRoadmapExtraFormData.image"
                    type="text"
                    class="form-control"
                    required
                  />
                </div> -->
                <div class="col-md-12 mb-3">
                  <label for="validationcareer_name">{{cvCareerNameLabel}}<span class="primary-color font-size-16">*</span></label>
                  <select v-model="vmGPathrRoadmapExtraFormData.career_name" class="form-control" title="Choose Career Name">
                    <option :value="null" disabled>Choose Career Name</option>
                    <option v-for="(career, index) of CareerName" :key="(index+1)" :value="career.career_name">
                      {{career.career_name}}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="Class">{{cvClassLabel}}</label>
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="classes"
                      :options="Class"
                      name="flavour-2"
                      multiple
                    />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationname_key">{{cvSkillNameLabel}}<span class="primary-color font-size-16">*</span></label>
                  <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="skillname"
                  :options="gpathSkillList"
                  name="flavour-1"
                  stacked
                  multiple
                />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="gpathRoadmapExtraAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { GPathrRoadmapExtras } from "../../../FackApi/api/GPathrRoadmapExtra.js"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"
import { GpathRoadmaps } from "../../../FackApi/api/GpathRoadmap"
import { GPathSkills } from "../../../FackApi/api/GPathSkill"

export default {
  name: "GPathrRoadmapExtraAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "gpath_roadmap_extra_add",
      cvCardTitle: "Add GPaths Roadmap Extra",
      cvCardSubHeader: "Add GPaths Roadmap Extra",
      cvSubmitBtn: "Add",
      cvNameLabel: "name",
      cvNameKeyLabel: "name key",
      cvClassLabel: "class",
      cvSkillNameLabel: "SelectSkill",
      cvTitleLabel: "title",
      cvDescriptionLabel: "description",
      cvUrlLabel: "url",
      // cvImageLabel: "image",
      cvCareerNameLabel: "career name",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "GPathrRoadmapExtra Add Response",
      vmGPathrRoadmapExtraFormData: Object.assign({}, this.initFormData()),
      vmGPathrRoadmapExtraDesc: null,
      vmGPathrRoadmapExtraType: null,
      name: [],
      classes: [],
      skillname: [],
      CareerName: {},
      Class: {
        "12": "12",
        "11": "11",
        "10": "10",
        "9": "9",
        "8": "8",
        "7": "7"
      },
      gpathSkillList: null
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    await this.gpathRoadmapFilterList()
    await this.gpath_skillList()
    this.gpathRoadmapList()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "name": "",
        "title": "",
        "description": "",
        "url": "",
        "skills": "",
        "class": "",
        // "image": "",
        "career_name": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGPathrRoadmapExtraFormData) {
          if (!this.vmGPathrRoadmapExtraFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapExtraAdd
     */
    async gpathRoadmapExtraAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        this.vmGPathrRoadmapExtraFormData.class = this.classes.join(",")
        this.vmGPathrRoadmapExtraFormData.skills = this.skillname.join(",")
        let gpathRoadmapExtraAddResp = await GPathrRoadmapExtras.gpathRoadmapExtraAdd(this, this.vmGPathrRoadmapExtraFormData)
        await ApiResponse.responseMessageDisplay(this, gpathRoadmapExtraAddResp)
        if (gpathRoadmapExtraAddResp && !gpathRoadmapExtraAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGPathrRoadmapExtraAddModal", gpathRoadmapExtraAddResp.resp_data)
        }
        this.vmGPathrRoadmapExtraFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapExtraAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * gpathRoadmapList
     */
    async gpathRoadmapList () {
      try {
        let gpathRoadmapListResp = await GpathRoadmaps.gpathRoadmapList(this)
        if (gpathRoadmapListResp.resp_status) {
          this.gpathRoadmapObjList = gpathRoadmapListResp.resp_data.data
          this.name = this.gpathRoadmapObjList.map((gpathsObj) => {
            return { value: gpathsObj.name }
          })
          this.name = this.gpathRoadmapObjList.map((gpathsObj) => {
            return { value: gpathsObj.name }
          })
          this.totalRows = gpathRoadmapListResp.resp_data.count
        }
        else {
          this.toastMsg = gpathRoadmapListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpathRoadmapList() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapFilterList
    */
    async gpathRoadmapFilterList () {
      try {
        let gpathRoadmapFilterListResp = await GPathrRoadmapExtras.gpathRoadmapFilterList(this)
        if (gpathRoadmapFilterListResp) {
          this.gpath_roadmap_Filter_list = gpathRoadmapFilterListResp.resp_data.data
          this.CareerName = this.gpath_roadmap_Filter_list.career_name
        }
        else {
          this.toastMsg = gpathRoadmapFilterListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_roadmap_filter_list() and Exception:", err.message)
      }
    },
    /**
     * gpath_skillList
     */
    async gpath_skillList () {
      try {
        let gpathSkillListResp = await GPathSkills.gpath_skillList(this)
        if (gpathSkillListResp.resp_status) {
          this.gpathSkillListResp = gpathSkillListResp.resp_data.data

          // Clear existing list before populating
          this.gpathSkillList = []

          for (let key of this.gpathSkillListResp) {
            this.gpathSkillList.push({ text: key.name, value: key.name })
          }

          this.totalRows = gpathSkillListResp.resp_data.count
        }
        else {
          this.toastMsg = gpathSkillListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_skillList() and Exception:", err.message)
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
