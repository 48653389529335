<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="gpathRoadmapExtraAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mb-2" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                <select v-model="whereFilter.name" class="form-control" @change="setFilters" title="Choose Activity">
                  <option :value="null" disabled>Choose Activity</option>
                  <option :value=null>All</option>
                  <option v-for="(Roadmap, index) of RoadmapName" :key="(index+1)" :value="Roadmap.name">
                    {{Roadmap.name}}
                  </option>
                </select>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mb-2" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                <select v-model="whereFilter.career_name" class="form-control" @change="setFilters" title="Choose Career Name">
                  <option :value="null" disabled>Choose Career Name</option>
                  <option :value=null>All</option>
                  <option v-for="(career, index) of CareerName" :key="(index+1)" :value="career.career_name">
                    {{career.career_name}}
                  </option>
                </select>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mb-3" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn Download_button mb-1" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button mb-1 ml-1" />
                <VueJsonToCsv :json-data="gpathRoadmapExtraObjList" csv-title="gpath_roadmap_extra" v-if="gpathRoadmapExtraObjList && gpathRoadmapExtraObjList.length">
                  <b-btn variant="primary" class="Download_button mb-1 mr-1">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpathRoadmapExtraObjList && gpathRoadmapExtraObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpathRoadmapExtraObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(title)="data">
                    <span>{{data.item.title}}</span><br>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" v-if="pageIsUsedForProfBuildReco" title="Recommend Activity" @click="profBuildRecoNotesAdd(data.item)"><i class="fa-solid fa-heart-circle-plus m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="gpathRoadmapExtraEdit('MODAL', data.item)" v-if="!data.item.editable" size="sm" title=""><i class="ri-ball-pen-fill m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showGPathrRoadmapExtraDeleteDialog(data.item)" size="sm" title=""><i class="ri-delete-bin-line m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="gotoActivityURL(data.item.url)" title=""><i class="fa-brands fa-chrome m-0 font-size-20 primary-color" aria-hidden="true"></i></b-button><br>
                    <b-badge>{{data.item.name}}</b-badge>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGPathrRoadmapExtraAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <GPathrRoadmapExtraAdd :propOpenedInModal="true" @emitCloseGPathrRoadmapExtraAddModal="closeGPathrRoadmapExtraAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpathRoadmapExtraAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathrRoadmapExtraAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGPathrRoadmapExtraEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <GPathrRoadmapExtraEdit :propOpenedInModal="true" :propGPathrRoadmapExtraObj="gpathRoadmapExtraEditObj" @emitCloseGPathrRoadmapExtraEditModal="closeGPathrRoadmapExtraEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpathRoadmapExtraEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathrRoadmapExtraEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGPathrRoadmapExtraDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGPathrRoadmapExtraDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpathRoadmapExtraDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GPathrRoadmapExtras } from "../../../FackApi/api/GPathrRoadmapExtra.js"
import GPathrRoadmapExtraAdd from "./GPathrRoadmapExtraAdd.vue"
import GPathrRoadmapExtraEdit from "./GPathrRoadmapExtraEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"
import { SpcmNotess } from "../../../FackApi/api/spcmNotes.js"

export default {
  name: "GPathrRoadmapExtraList",
  components: {
    GPathrRoadmapExtraAdd,
    GPathrRoadmapExtraEdit,
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      apiName: "gpath_roadmap_extra_list",
      cvModuleFile: "gpath_roadmap_extra",
      cvModulePrefix: "GPRME",
      cvCardTitle: "GPaths Roadmap Extras",
      cvbtnAddNew: "Add",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPaths Roadmap Extra Curricular / Profile Building Activities",
      cvAddModalHeader: "Add GPaths Roadmap Extra Curricular / Profile Building Activities",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPaths Roadmap Extra Curricular / Profile Building Activities",
      showModelGPathrRoadmapExtraAdd: false,
      showModelGPathrRoadmapExtraEdit: false,
      showModelGPathrRoadmapExtraDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "title", key: "title", class: "text-left align-text-top w-300px", sortable: true },
        { label: "description", key: "description", class: "text-left align-text-top w-350px", sortable: true },
        { label: "class", key: "class", class: "text-left align-text-top", sortable: true },
        { label: "skills", key: "skills", class: "text-left align-text-top", sortable: true },
        { label: "career name", key: "career_name", class: "text-left align-text-top", sortable: true }
      ],
      gpathRoadmapExtraObjList: null,
      gpathRoadmapExtraEditObj: null,
      filter: null,
      filterOn: [],
      CareerName: {},
      RoadmapName: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      delObj: null,
      whereFilter: {
        "name": "null",
        "career_name": "null"
      },
      name: "",
      career_name: "",
      pageIsUsedForProfBuildReco: false
    }
  },
  props: {
    propSpcmObj: {
      type: Object,
      dafault: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.gpathRoadmapFilterList()
    if (this.propSpcmObj || (this.$route.query.user_id && this.$route.query.spcm_id)) {
      this.pageIsUsedForProfBuildReco = true
    }

    this.gpathRoadmapExtraList()
  },
  methods: {
    /**
     * gpathRoadmapExtraList
     */
    async gpathRoadmapExtraList () {
      let whereFilter = {}
      for (const property in this.whereFilter) {
        if (this.whereFilter[property] && this.whereFilter[property] != "null" && this.whereFilter[property] != " ") {
          whereFilter[property] = this.whereFilter[property]
          // console.log(`${property}: ${this.whereFilter[property]}`)
        }
      }
      try {
        let gpathRoadmapExtraListResp = await GPathrRoadmapExtras.gpathRoadmapExtraList(this, this.whereFilter)
        if (gpathRoadmapExtraListResp.resp_status) {
          this.gpathRoadmapExtraObjList = gpathRoadmapExtraListResp.resp_data.data
          this.totalRows = gpathRoadmapExtraListResp.resp_data.count
        }
      }
      catch (err) {
        console.log("Exception occurred at gpathRoadmapExtraList() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapExtraAdd
     */
    gpathRoadmapExtraAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpathRoadmapExtra_add")
        }
        else {
          this.showModelGPathrRoadmapExtraAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapExtraAdd() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapExtraEdit
     */
    gpathRoadmapExtraEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpathRoadmapExtra_edit/" + this.gpathRoadmapExtraEditObj.gprme_id)
        }
        else {
          let tempItem = { ...item }
          this.gpathRoadmapExtraEditObj = tempItem
          this.showModelGPathrRoadmapExtraEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapExtraEdit() and Exception:", err.message)
      }
    },
    /**
     * showGPathrRoadmapExtraDeleteDialog
     */
    showGPathrRoadmapExtraDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGPathrRoadmapExtraDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGPathrRoadmapExtraDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapExtraDelete
     */
    async gpathRoadmapExtraDelete () {
      try {
        let gpathRoadmapExtraDelResp = await GPathrRoadmapExtras.gpathRoadmapExtraDelete(this, this.delObj.gprme_id)
        await ApiResponse.responseMessageDisplay(this, gpathRoadmapExtraDelResp)

        if (gpathRoadmapExtraDelResp && !gpathRoadmapExtraDelResp) {
          this.showModelGPathrRoadmapExtraDelete = false
          return false
        }

        let index = this.gpathRoadmapExtraObjList.indexOf(this.delObj)
        this.gpathRoadmapExtraObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGPathrRoadmapExtraDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapExtraDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGPathrRoadmapExtraAddModal
     */
    closeGPathrRoadmapExtraAddModal (gpathRoadmapExtraAddData) {
      try {
        if (gpathRoadmapExtraAddData) {
          if (this.gpathRoadmapExtraObjList && this.gpathRoadmapExtraObjList.length >= 1) {
            let gpathRoadmapExtraObjLength = this.gpathRoadmapExtraObjList.length
            let lastId = this.gpathRoadmapExtraObjList[gpathRoadmapExtraObjLength - 1]["id"]
            gpathRoadmapExtraAddData.id = lastId + 1
          }
          else {
            this.gpathRoadmapExtraObjList = []
            gpathRoadmapExtraAddData.id = 11111
          }

          gpathRoadmapExtraAddData.created_on = moment()
          this.gpathRoadmapExtraObjList.unshift(gpathRoadmapExtraAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGPathrRoadmapExtraAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpathRoadmapExtraAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGPathrRoadmapExtraEditModal
     */
    closeGPathrRoadmapExtraEditModal () {
      try {
        this.gpathRoadmapExtraList()
        this.showModelGPathrRoadmapExtraEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGPathrRoadmapExtraEditModal() and Exception:", err.message)
      }
    },
    /**
     * profBuildRecoNotesAdd
     */
    async profBuildRecoNotesAdd (pbaObj) {
      try {
        let payload = {
          session_title: pbaObj.name,
          spcm_id: this.propSpcmObj.spcm_id ? this.propSpcmObj.spcm_id : this.$route.query.spcm_id,
          note_type: 3, // 1 for note and 2 for comment and 3 for profile_building_activity notes
          note_desc: JSON.stringify({
            title: pbaObj.title,
            description: pbaObj.description,
            url: pbaObj.url
          }),
          note_public_type: 1,
          notes_module_obj_id: this.propSpcmObj.user_id ? this.propSpcmObj.user_id : this.$route.query.user_id,
          counsellor_id: this.userData.user_id,
          gide_user_id: this.propSpcmObj.user_id ? this.propSpcmObj.user_id : this.$route.query.user_id,
          session_id: "dummy_session_id"
        }

        let spcmProfBuildNotesAddResp = await SpcmNotess.spcmNotesAdd(this, payload)
        if (spcmProfBuildNotesAddResp.resp_status) {
          this.$emit("emitAddProfBuildReco")

          this.toastMsg = "Profile building activity added"
          this.toastVariant = "success"
          this.showToast = true
        }
        else {
          ApiResponse.responseMessageDisplay(this, spcmProfBuildNotesAddResp)
        }
      }
      catch (err) {
        console.error("Exception in profBuildRecoNotesAdd and err: ", err.message)
      }
    },
    /*
     * goto Activity URL
     */
    gotoActivityURL (url) {
      if (url) {
        window.open(url, "_blank")
      }
    },
    /**
     * gpathRoadmapFilterList
    */
    async gpathRoadmapFilterList () {
      try {
        let gpathRoadmapFilterListResp = await GPathrRoadmapExtras.gpathRoadmapFilterList(this)
        if (gpathRoadmapFilterListResp) {
          this.gpath_roadmap_Filter_list = gpathRoadmapFilterListResp.resp_data.data
          this.CareerName = this.gpath_roadmap_Filter_list.career_name
        }
        else {
          this.toastMsg = gpathRoadmapFilterListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_roadmap_filter_list() and Exception:", err.message)
      }
    },
    /**
     * setFilters
    */
    async setFilters () {
      await this.gpathRoadmapExtraList()
    }
  }
}
</script>
